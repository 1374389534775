import request from '@/utils/request'

// 代煎列表
export function getDrugAgentList(params) {
  return request({
    url: 'admin/presTisan/index',
    method: 'GET',
    params
  })
}

// 代煎审核
export function postDrugAgentExam(data, prescriptionTisan) {
  return request({
    url: `admin/presTisan/examine/${prescriptionTisan}`,
    method: 'POST',
    data
  })
}

// 评论列表
export function getAppraiseList(params) {
  return request({
    url: 'admin/appraise/index',
    method: 'GET',
    params
  })
}

//  审核
export function getAppraiseExamineStatus(params, id) {
  return request({
    url: `admin/appraise/examineStatus/${id}`,
    method: 'GET',
    params
  })
}

// 物流接口 煎药
export function getOrderExpress(params) {
  return request({
    url: 'admin/order/orderExpress',
    method: 'GET',
    params
  })
}
//中药发货
export function postSendOrder(data,id) {
  return request({
    url: `admin/sendOrder/${id}`,
    method: 'post',
    data
  })
}


// 导出代煎列表
export function exportDrugAgentList(params) {
  return request({
    url: 'admin/presTisan/export',
    method: 'GET',
    params,
    responseType: 'blob',
  })
}
// 退款
export function refound(data,order) {
  return request({
    url: `admin/presTisan/refound/${order}`,
    method: 'POST',
    data,
  })
}
