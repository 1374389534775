<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('examine') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									background-color: #2362FB; border-color: #2362FB"
            @click="handleAudit(multipleSelectionPres[0], '1')"
            v-if="multipleSelectionPres.length == 1"
            >审核
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('examine') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									background-color: #2362FB; border-color: #2362FB"
            @click="handleAudit(multipleSelectionPres[0], '2')"
            v-if="multipleSelectionPres.length == 1"
            >拒绝
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('view') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									background-color: #2362FB; border-color: #2362FB"
            @click="handleD(multipleSelectionPres[0], 'view')"
            v-if="
              multipleSelectionPres.length == 1 &&
                multipleSelectionPres[0].examine_status != 0
            "
            >查看
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                currUser = scope.row;
                getInfo(scope.row.doctors_id);
              "
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'text' &&
              column.visible === true &&
              column.column_label == '审核状态'
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.examine_status == 0">未审核</span>
            <span
              v-else-if="scope.row.examine_status == 1"
              style="color: #2eae00"
              >已审核</span
            >
            <span v-else style="color: red">已拒绝</span>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-date-picker
                v-if="'created_at' == column.column_prop"
                v-model="choiceDate"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH-mm-ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleChangeDate"
              >
              </el-date-picker>
              <el-select
                v-if="'examine_status' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.examine_status)
                "
                v-model="searchParams.examine_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'order_sn' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.order_sn"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.order_sn)
                "
              >
              </el-input>
              <el-input
                v-if="'doctor_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.doctor_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.doctor_name)
                "
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  getAppraiseList,
  getAppraiseExamineStatus,
} from "@/api/audit/drugAgentAudit";
import commentAuditTableHead from "./tableHead/commentAuditTableHead";
import editTableHead from '@/components/editTableHead/editTableHead'
import { getAdminFieldIndex } from "@/api/drugs2.0";
import { mapState } from "vuex";
export default {
  name: "CommentAudit",
  components: {
    Pagination,
    // eslint-disable-next-line vue/no-unused-components
    editTableHead,
  },
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  computed: {
    ...mapState({
      table_options: (state) => state.user.table_options,
    }),
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  data() {
    return {
      table_type: "commentAudit",
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "order_sn",
          isSearch: false,
        },
        {
          name: "doctor_name",
          isSearch: false,
        },
        {
          name: "examine_status",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      choiceDate: [],
      searchParams: {
        created_at: [],
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      options: [
        {
          value: 0,
          label: "未审核",
        },
        {
          value: 1,
          label: "已审核",
        },
        {
          value: 2,
          label: "已拒绝",
        },
      ],
      tableData: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "commentAudit",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.commentAudit;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.page_size = this.listQuery.limit;
      getAppraiseList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = _data.per_page;
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },

    handleChangeDate() {
      this.searchParams.start_at = [];
      this.searchParams.end_at = [];
      console.log(this.choiceDate);
      var _tmp0;
      var _tmp1;
      if (this.choiceDate) {
        _tmp0 = this.choiceDate[0].substring(0, 10);
        _tmp1 = this.choiceDate[1].substring(0, 10);
        this.searchParams.start_at.push(`${_tmp0} 00:00:00`);
        this.searchParams.end_at.push(`${_tmp1} 23:59:59`);
      } else {
        this.searchParams.start_at = [];
        this.searchParams.end_at = [];
      }
      this.getList("restPage");
    },
    handleAudit(row, audit) {
      console.log(row, audit);
      var str = audit == 2 ? "确认拒绝" : "确认通过";
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          var params = {
            examine_status: audit,
          };
          getAppraiseExamineStatus(params, row.appraise_id)
            .then((res) => {
              if (res.code == 200) {
                this.getList();
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.message,
                });
              }
              this.listLoading = false;
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: "网络错误",
              });
              this.listLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSee(index, row) {},
  },
};
</script>

<style lang="scss" scoped></style>
